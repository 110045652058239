import { useContext, useState, useEffect } from "react";
import { CircularProgress, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { ProductContext } from "../Context/ProductProvider";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { SharedContext } from "../Context/SharedProvider";
import { CommentContext } from "../Context/CommentProvider";
import SendIcon from "@mui/icons-material/Send";
import "./product.css";
import Product from "../Interfaces/Product";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

export default function ProductPage() {
  let { id } = useParams<"id">();
  const { likes, likeProduct, getProduct } = useContext(ProductContext);
  const { setLocation, loading } = useContext(SharedContext);
  const { productComment, postComment } = useContext(CommentContext);

  const [comment, setComment] = useState<string>("");

  const [clicked, setClicked] = useState<Product | null>(null);

  useEffect(() => {
    setLocation(false);
    if (id !== undefined) {
      getProduct(id).then((e: any) => setClicked(e));
    }
    window.scrollTo(0, 0);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleLikeButton = (id: any) => {
    likeProduct(id);
  };

  const sendComment = () => {
    postComment(comment);
    setComment("");
    let newComment = {
      Comment: comment,
      ErrorMessage: "Başarılı",
      Id: Math.random() * 1000,
      IsReply: false,
      ProductId: clicked?.Id,
      ResultState: true,
    };

    productComment?.push(newComment);
  };

  const handleEnter = (e: { key: string }) => {
    if (e.key === "Enter") {
      postComment(comment);
      setComment("");
      let newComment = {
        Comment: comment,
        ErrorMessage: "Başarılı",
        Id: Math.random() * 1000,
        IsReply: false,
        ProductId: clicked?.Id,
        ResultState: true,
      };

      productComment?.push(newComment);
    }
  };

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  return (
    <>
      {" "}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          background: "#F5F5F5",
          paddingTop: 10,
          marginTop: 5,
          marginBottom: 10,
          paddingBottom: 20,
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Card
            sx={{
              margin: 1,
              height: 865,
              boxShadow: 0,
              border: 0,
            }}
          >
            <CardContent
              sx={{
                justifyContent: "space-between",
              }}
            >
              <div
                className="product-zoom-image"
                style={{
                  cursor: "zoom-in",
                }}
              >
                <Grid container spacing={1}>
                  <PhotoProvider>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                      {clicked !== null && (
                        <PhotoView src={clicked.Images[0].ImageUrl}>
                          <img
                            className="columnBig"
                            src={clicked.Images[0].ImageUrl}
                            alt=""
                            style={{
                              height: 600,
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </PhotoView>
                      )}
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      {clicked !== null &&
                        clicked.Images.length > 1 &&
                        clicked.Images.slice(1).map((image, index) => (
                          <PhotoView key={index} src={image.ImageUrl}>
                            <img
                              className="columnSmall"
                              src={image.ImageUrl}
                              alt=""
                              style={{
                                height: 50,
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </PhotoView>
                        ))}
                    </Grid>
                  </PhotoProvider>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Card
            sx={{
              margin: 1,
              boxShadow: 0,
              border: 0,
              height: 450,
            }}
          >
            {loading ? (
              <CardContent>
                <Stack direction="row" justifyContent="center">
                  <CircularProgress />
                </Stack>
              </CardContent>
            ) : (
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  {`Product Name: ${clicked?.StyleNo}`}
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  {`Fabric:`}
                </Typography>
                {clicked?.Fabric1 !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`${clicked?.Fabric1}`}
                  </Typography>
                )}
                {clicked?.Fabric2 !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`${clicked?.Fabric2}`}
                  </Typography>
                )}
                {clicked?.Fabric3 !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`${clicked?.Fabric3}`}
                  </Typography>
                )}
                {clicked?.Fabric4 !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`${clicked?.Fabric4}`}
                  </Typography>
                )}
                {clicked?.Fabric5 !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`${clicked?.Fabric5}`}
                  </Typography>
                )}
                {clicked?.Description !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`Description: ${clicked?.Description}`}
                  </Typography>
                )}
                {clicked?.FabricCode !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`FabricCode: ${clicked?.FabricCode}`}
                  </Typography>
                )}
                {clicked?.FabricCompany !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`FabricCompany: ${clicked?.FabricCompany}`}
                  </Typography>
                )}
                {clicked?.FabricPrice !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`FabricPrice: ${clicked?.FabricPrice}`}
                  </Typography>
                )}
                {clicked?.SalePrice !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`SalePrice: ${clicked?.SalePrice}`}
                  </Typography>
                )}
                {clicked?.Quantity !== undefined && clicked?.Quantity > 0 && (
                  <Typography variant="h6" color="text.secondary">
                    {`Quantity: ${clicked?.Quantity}`}
                  </Typography>
                )}
                {clicked?.Moq !== undefined && clicked?.Moq !== null && clicked?.Moq !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`Moq: ${clicked?.Quantity}`}
                  </Typography>
                )}
                {clicked?.LeadTime !== undefined && clicked?.LeadTime !== null && clicked?.LeadTime !== "" && (
                  <Typography variant="h6" color="text.secondary">
                    {`Lead Time: ${clicked?.LeadTime}`}
                  </Typography>
                )}
              </CardContent>
            )}

            <IconButton aria-label="add to favorites" onClick={() => handleLikeButton(clicked?.Id)}>
              {" "}
              {clicked !== null ? likes.includes(clicked.Id) ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon color="error" /> : null}
            </IconButton>
          </Card>

          <Card
            sx={{
              margin: 1,
              boxShadow: 0,
              border: 0,
              height: 260,

              overflow: "auto",
            }}
          >
            <CardHeader title="Comments" />

            <CardContent>
              {loading ? (
                <Stack direction="row" justifyContent="center">
                  <CircularProgress />
                </Stack>
              ) : (
                productComment?.map((e) => {
                  return (
                    <Stack direction="row" justifyContent={e.IsReply ? "end" : "start"} key={e.Id}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                          borderRadius: "10px",
                          margin: "5px",
                          padding: "10px",
                          display: "inline-block",
                          bgcolor: e.IsReply ? "#ffc400" : "#4dabf5",
                        }}
                      >
                        {e.Comment}
                      </Typography>
                    </Stack>
                  );
                })
              )}
            </CardContent>
          </Card>

          <Card
            sx={{
              margin: 1,
              marginBottom: 10,
              boxShadow: 0,
              border: 0,
              height: 65,
            }}
          >
            <TextField
              id="comment-input"
              label="Write comment"
              variant="standard"
              fullWidth
              color="error"
              onKeyDown={handleEnter}
              onChange={handleCommentChange}
              value={comment}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={sendComment}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
