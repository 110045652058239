import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import HeaderBar from "./Components/HeaderBar/HeaderBar";
import Home from "./Pages/Home";
import ProductProvider from "./Context/ProductProvider";
import UserProvider from "./Context/UserProvider";
import Product from "./Pages/Product";
import Login from "./Pages/Login";
import Collection from "./Pages/Collection";
import SharedProvider from "./Context/SharedProvider";
import CommentProvider from "./Context/CommentProvider";

function App() {
  const location = useLocation();

  function RequireAuth({ children }: { children: JSX.Element }) {
    const token = localStorage.getItem("token");
    const redirect = localStorage.getItem("redirectCollection");

    if (token === null || token === undefined) {
      if (location.pathname.includes("collection") && (redirect === undefined || redirect === null)) {
        localStorage.setItem("redirectCollection", location.pathname);
      }
      return <Navigate to="/login" />;
    }
    return children;
  }

  return (
    <div className="App">
      <SharedProvider>
        <UserProvider>
          <CommentProvider>
            <ProductProvider>
              <HeaderBar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Home />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/:id"
                  element={
                    <RequireAuth>
                      <Product />
                    </RequireAuth>
                  }
                />
                <Route
                  path="collection/:id"
                  element={
                    <RequireAuth>
                      <Collection />
                    </RequireAuth>
                  }
                />
              </Routes>
            </ProductProvider>
          </CommentProvider>
        </UserProvider>
      </SharedProvider>
    </div>
  );
}

export default App;
