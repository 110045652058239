import { createContext, useState, FC, useEffect, useContext } from "react";
import { UserContextState } from "./ContextType";
import axios from "axios";
import { SharedContext } from "./SharedProvider";
import { useNavigate } from "react-router-dom";
const contextDefaultValues: UserContextState = {
  loginUser: function (): void {},
  logout: function (): void {},
  user: null,
};

export const UserContext = createContext<UserContextState>(contextDefaultValues);

const UserProvider: FC = ({ children }) => {
  const { changeNotification, changeLoading } = useContext(SharedContext);
  const navigate = useNavigate();
  const [user, setUser] = useState<Object | null>(contextDefaultValues.user);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      autoLogin();
    }
    return () => {};
  }, []);

  const autoLogin = async () => {
    let result = await axios.post(`${process.env.REACT_APP_SERVER}/api/user/me`, {
      token: localStorage.getItem("token"),
    });
    if (result.data.status) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("userName", result.data.userName);
      localStorage.setItem("userMail", result.data.userMail);
      localStorage.setItem("adminMail", result.data.adminMail);
      setUser(result.data);
    } else {
      changeNotification(true, result.data.error);
    }
  };

  const loginUser = async (user: any) => {
    changeLoading(true);
    try {
      let result = await axios.post(`${process.env.REACT_APP_SERVER}/api/user/login`, {
        user,
      });
      if (result.data.status) {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userName", result.data.userName);
        localStorage.setItem("userMail", result.data.userMail);
        localStorage.setItem("adminMail", result.data.adminMail);
        setUser(result.data.token);
      } else {
        changeNotification(true, result.data.error);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err.message);
      }
    }
    changeLoading(false);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <UserContext.Provider
      value={{
        logout,
        loginUser,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
