import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { ProductContext } from "../../Context/ProductProvider";
import { UserContext } from "../../Context/UserProvider";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SharedContext } from "../../Context/SharedProvider";
import { Button } from "@mui/material";

export default function HeaderBar() {
  const { filterLikes, likes, filterState, miniCollectionId, getMiniCollection } = useContext(ProductContext);
  const { user, logout } = useContext(UserContext);
  const { home } = useContext(SharedContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const goBack = () => {
    if (miniCollectionId !== "") {
      navigate("/collection/" + miniCollectionId);
    } else {
      navigate("/");
    }
  };

  const goHome = () => {
    getMiniCollection("");
    navigate("/");
  };

  const handleFilterLikes = () => {
    filterLikes(filterState.likeFilter);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{ background: "#FFFFFF", zIndex: 10000 }}>
        <Toolbar>
          {home ? (
            <>
              <img
                src="/logo.png"
                loading="lazy"
                alt="Logo"
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  height: 40,
                }}
                onClick={() => goHome()}
              />
            </>
          ) : (
            <IconButton onClick={() => goBack()}>
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </IconButton>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              flexDirection: "row-reverse",
              justifyContent: "flex-end",
            }}
          >
            {user && home ? (
              <>
                <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleFilterLikes}>
                  <Badge badgeContent={likes.length} color="error">
                    {filterState.likeFilter ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                  </Badge>
                </IconButton>
              </>
            ) : null}
            {user ? (
              <Button sx={{ color: "black", textTransform: "capitalize" }} onClick={handleLogout} endIcon={<LogoutIcon />}>
                {user.userName}
              </Button>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
