import { SetStateAction, useContext, useEffect, useState } from "react";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Grid } from "@mui/material";
import { ProductContext } from "../Context/ProductProvider";
import LoadingCard from "../Components/LoadingCard/LoadingCard";
import Notification from "../Components/Notification/Notification";
import { SharedContext } from "../Context/SharedProvider";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Product from "../Interfaces/Product";

export default function Collection() {
  const { getMiniCollection } = useContext(ProductContext);
  const { loading, setLocation } = useContext(SharedContext);

  const [products, setProducts] = useState<Array<Product> | null>(null);

  let { id } = useParams<"id">();

  useEffect(() => {
    setLocation(true);

    const redirect = localStorage.getItem("redirectCollection");
    if (redirect !== undefined && redirect !== null) {
      localStorage.removeItem("redirectCollection");
    }

    if (id !== undefined) {
      getMiniCollection(id).then((e: SetStateAction<Product[] | null>) =>
        setProducts(e)
      );
    }

    const scrollPosition = sessionStorage.getItem("scrollPosition");
    window.scrollTo(0, 0);
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <Notification />
      <Grid
        container
        spacing={1}
        sx={{ background: "#F5F5F5", paddingTop: 10, paddingBottom: 20 }}
        direction="row"
      >
        {loading
          ? loader?.map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={item}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingCard key={item} />
                </Grid>
              );
            })
          : products?.map((product) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={product.Id}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <motion.div layout>
                    <ProductCard obj={product} key={product.Id} />
                  </motion.div>
                </Grid>
              );
            })}
      </Grid>
    </>
  );
}
