import { useContext, useEffect } from "react";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Dialog, DialogTitle, Grid } from "@mui/material";
import { ProductContext } from "../Context/ProductProvider";
import LoadingCard from "../Components/LoadingCard/LoadingCard";
import Notification from "../Components/Notification/Notification";
import SearchBarAlternate from "../Components/SearchBarAlternate/SearchBarAlternate";
import FilterTabs from "../Components/FilterTabs/FilterTabs";
import FilterSubTabs from "../Components/FilterSubTabs/FilterSubTabs";
import { SharedContext } from "../Context/SharedProvider";
import { motion } from "framer-motion";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";

export default function Home() {
  const { products } = useContext(ProductContext);
  const { loading, setLocation } = useContext(SharedContext);

  useEffect(() => {
    setLocation(true);
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    window.scrollTo(0, 0);
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
    return () => {};
  }, []);

  let loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <Notification />
      <Grid container spacing={1} sx={{ background: "#F5F5F5" }} direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchBarAlternate />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterTabs />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterSubTabs />
        </Grid>
        <Dialog open={loading}>
          <DialogTitle>Collections loading...</DialogTitle>
        </Dialog>
        {loading
          ? loader?.map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={item}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingCard key={item} />
                </Grid>
              );
            })
          : products?.map((product) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={product.Id}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <motion.div layout>
                    <ProductCard obj={product} key={product.Id} />
                  </motion.div>
                </Grid>
              );
            })}
        <Fab variant="extended" sx={{ position: "fixed", bottom: 36, right: 36 }} onClick={() => window.scrollTo(0, 0)}>
          <NavigationIcon sx={{ mr: 1 }} />
          TOP
        </Fab>
      </Grid>
    </>
  );
}
